<template>
  <div class="timingDiv">
    <div class="ternary-system">
      <div class="sun primary"></div>
      <div class="sun secondary"></div>
      <div class="sun ternary"></div>
    </div>
    <div class="sand">
      <div class="pendulums">
        <div class="pendulum">
          <div class="bar"></div>
          <div class="motion">
            <div class="string"></div>
            <div class="weight"></div>
          </div>
        </div>
        <div class="pendulum shadow">
          <div class="bar"></div>
          <div class="motion">
            <div class="string"></div>
            <div class="weight"></div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <!-- <div class="pyramid-bg"></div>
    <div class="pyramid"></div>

    <div class="piramidText">
      <h2>Brevemente</h2>
      <h1>Loja online</h1>
    </div> -->

    <div class="mainDiv">
      <v-img class="logo"> </v-img>
      <h1 class="title">
        O melhor de <strong>tudo</strong>
        <b> num só lugar! </b>
      </h1>

      <v-container class="mt-10">
        <v-row>
          <v-col>
            <v-btn
              variant="outlined"
              class="domusBtn mb-3"
              block
              flat
              color="#ef7c00"
              @click="goTo('https://domusmelody.pt')"
              >Página Inicial</v-btn
            >

            <v-btn
              variant="outlined"
              class="domusBtn mb-3"
              block
              flat
              color="#ef7c00"
              @click="goTo('https://noticias.domusmelody.pt')"
              >Notícias em Destaque</v-btn
            >
            <!-- <v-btn
              variant="outlined"
              class="domusBtn mb-3"
              block
              flat
              color="#ef7c00"
              >Produtos em Destaque</v-btn
            >
            <v-btn
              variant="outlined"
              class="domusBtn mb-3"
              block
              flat
              color="#ef7c00"
              >Campanhas a Decorrer</v-btn
            > -->
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col style="padding: 0px" class="text-center">
            <v-icon size="45px" class="redeIcon mx-2">mdi-instagram</v-icon>
            <v-icon size="45px" class="redeIcon mx-2">mdi-facebook</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup>
function goTo(url) {
  window.open ? window.open(url, "_blank") : (window.location.href = url);
}
</script>

<style lang="scss" scoped>
.mainDiv {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 50px;
  text-align: center;
}
.redeIcon {
  filter: drop-shadow(0px 0px 1px #fff);
}

.domusBtn {
  box-shadow: -2px 2px 0 1px #ef7c00;
  border-radius: 0px;
  background-color: white;
}

.timingDiv {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.pyramid {
  $size: 100vw;
  position: absolute;
  right: 50vw;
  bottom: 0;
  width: 50vw;
  height: 1.2 * $size;
}

.pyramid::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../assets/site.png");
  background-size: cover;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  animation: slideBackground 120s infinite;
  filter: blur(10px);
}

.pyramid-bg {
  $size: 100vw;
  position: absolute;
  right: 50vw;
  bottom: 0;
  width: 50vw;
  height: 1.2 * $size;
}

.pyramid-bg::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-size: cover;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}

@keyframes slideBackground {
  0%,
  100% {
    background-position: center top;
  }
  50% {
    background-position: center bottom;
  }
}

// animation site
.scroll-bg {
  right: 50vw;
  width: 50vw;
  height: 100%;
  /* Using linear-gradient to simulate a pyramid effect */
  background-image: url("../assets/site.png");
  background-color: #00000091;
  background-size: cover;

  animation: scrollBg 35s linear forwards;
}

@keyframes scrollBg {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-330px);
  }
}

.piramidText {
  position: absolute;
  left: 2%;
  bottom: 6%;
  color: white;
  padding: 8px 10px;
  h1 {
    font-size: max(20px, 4vw);
    margin: 0;
    text-transform: uppercase;
  }
  h2 {
    font-size: max(16px, 1.5vw);
    margin: 0;
    font-weight: 100;
  }
}

.sand {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 9vw;
  min-height: 8rem;
  // background: #ffbd4d;
  background: #6b665b;
}

.ternary-system {
  position: absolute;
  bottom: 0;
  // right:17vw;
  left: 82vw;
  transform: translateX(-100%);
}

.sun {
  position: relative;
  border-radius: 50%;
  // background: #ffde72;
  background: #ef7c00;
}

.sun.primary {
  position: absolute;
  top: 15vw;
  left: 5vw;
  width: 1rem;
  height: 1rem;
}

.sun.secondary {
  position: absolute;
  top: 5vw;
  left: 11vw;
  width: 16vw;
  max-width: 6rem;
  height: 16vw;
  max-height: 6rem;
}

.sun.ternary {
  right: 0;
  transform: translateX(10.8rem);
  width: 60vw;
  max-width: 400px;
  height: 60vw;
  max-height: 400px;
}

.pendulums {
  position: absolute;
  width: 50px;
  height: 200px;
  right: 35vw;
  top: -120px;
  perspective: 400px;
}

.pendulum {
  position: absolute;
  width: 50px;
  height: 200px;
  right: 44%;
  top: 0;
  left: 50%;
  transform: translateZ(-300px) translateX(-50%);
}

.pendulum.shadow {
  top: 263%;
  left: -65%;
  bottom: 0;
  transform-origin: 50% 0;
  transform: translateX(-50%) scaleY(-1) scaleZ(2) rotateY(-30deg)
    rotateX(-75deg) translateZ(300px);
}

.pendulum .bar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: block;
  width: 20px;
  height: 200px;
  background: rgb(219, 219, 219);
}

.pendulum .string {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 150px;
  background: rgb(219, 219, 219);
}

.pendulum .weight {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgb(219, 219, 219);
}

.pendulum .motion {
  position: absolute;
  height: 200px;
  width: 50px;
  transform-origin: 50% 10px;
  animation: swing 2000ms infinite ease-in-out;
}

.pendulum.shadow .bar,
.pendulum.shadow .string,
.pendulum.shadow .weight {
  background: #3d3a34;
}

@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);

.title {
  text-align: center;
  font-size: 10px;
  font-family: "Oswald";
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.3em;
  margin-top: 16px;
  color: #222;
}

.title strong {
  // display: block;
  font-weight: 700;
}

.title em {
  font-style: normal;
  font-weight: 400;
}

p {
  position: absolute;
  left: 50%;
  transform: translateX(3.2em);
  font-family: "Oswald";
  font-size: 0.6em;
  color: #222;
  margin: 0 17px;
  padding: 0;
  text-align: right;
}

@keyframes swing {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}

.text {
  position: absolute;
  width: min(400px, 80vw);
  bottom: calc(63vh);
  right: 50%;
  top: 10%;
  transform: translateX(50%);
  font-size: 7vw;
}

.title {
  font-size: 22px;
}

.logo {
  content: url("../../public/images/logo.png");
  margin-bottom: 16px;
  max-width: 300px;
  margin: 0 auto;
}

.countdownDiv {
  margin-top: 20px;
}

.appFooter {
  color: white;
  position: absolute;
  bottom: 16px;
  left: 2%;
  padding-inline: 10px;
  margin-inline: auto;

  z-index: 999;
}

.ofLink {
  color: #e5426f;
  text-decoration: none;
  margin-left: 0.25rem;
}
</style>
