<template>
  <v-app id="app">
    <TimingComponent />
    <!-- <AppFooter></AppFooter> -->
     <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import TimingComponent from "./components/TimingComponent.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  name: "App",
  components: {
    TimingComponent,
    AppFooter,
  },
};
</script>

<style>
body, #app {
  /* background-color: #832929; */
  /* set an image as background */
  background-image: url('./assets/background.jpg');
}
</style>
