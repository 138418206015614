<template>
  <v-footer class="appFooter d-flex align-center">
    <!-- 👉 Footer: left content -->
    <span class="d-flex align-center">
      &copy;
      {{ new Date().getFullYear() }}
      Feito com
      <VIcon icon="mdi-heart" color="#e5426f" size="1.25rem" class="mx-1" />
      pela
      <a href="https://oficinasoft.pt" target="_blank" class="ofLink"
        >OficinaSoft</a
      >
    </span>
  </v-footer>
</template>

<style lang="scss" scoped>
.appFooter {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.ofLink {
  color: #e5426f;
  text-decoration: none;
  margin-left: 0.25rem;
}
</style>
